import Turbolinks from 'turbolinks';
import 'jquery';
import 'popper.js';
import { } from 'jquery-ujs'

Turbolinks.start();

import '../../images/index.js.erb';
import '../../javascripts/components/index.js.erb';

console.log('Loaded Application');
